import React from "react";
import styles from "../../styles/main.module.css";
import background from "../../assets/About Us/background.jpg";
import { useMediaQuery } from "react-responsive";

const MainCover = ({ title = "", subTitle = "" }) => {
  const isMobile = useMediaQuery({ maxWidth: "500px" });
  const isDesktop = useMediaQuery({ minWidth: "501px", maxWidth: "900px" });
  return (
    <>
      <div>
        <div
          style={{ minHeight: isMobile ? "120px" : isDesktop ? "250px" : "300px", backgroundImage: `url(${background}) `   ,justifyContent: isMobile || isDesktop ? "center" : "end",
          alignItems: isMobile || isDesktop ? "center" : "start", }}
          className={`${styles?.["about-cover"]} ${styles?.["p-l-3"]} ${styles?.["flex-column-end"]}  `}
        >
          <h1 className={`${isMobile?styles?.["w-100"]:styles?.["w-50"]} ${isMobile?styles?.["center"]:""} ${styles?.["m-l-3"]} `}>
            {title}
          </h1>
        </div>
        <div
          className={`${styles?.["gradiant-button"]} ${isMobile?styles?.["p-t-1"]:styles?.["p-t-3"]} ${isMobile?styles?.["p-b-1"]:styles?.["p-b-3"]} `}
        >
          <h3
            className={`${isMobile?styles?.["w-100"]:styles?.["w-50"]} ${styles?.["m-auto"]} ${styles?.["center"]}  `}
          >
            {subTitle}
          </h3>
        </div>
      </div>
    </>
  );
};
export default MainCover;
