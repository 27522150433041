import React from "react";
import Marquee from "react-fast-marquee";
import styles from "../../styles/main.module.css";
import channel2 from "../../assets/Media logos/CNBC.png";
// import channel8 from "../../assets/Media logos/lans.png";
import channel4 from "../../assets/Media logos/dailyhunt.png";
import channel14 from "../../assets/Media logos/MN.png";
import channel3 from "../../assets/Media logos/CXO.png";
import channel6 from "../../assets/Media logos/FP.png";

import channel19 from "../../assets/Media logos/republic.png";
import channel22 from "../../assets/Media logos/TB.png";
import channel7 from "../../assets/Media logos/HS.png";
import channel5 from "../../assets/Media logos/flipboard.png";
import channel9 from "../../assets/Media logos/indian bullet.png";
import channel10 from "../../assets/Media logos/IS (1).png";
// import channel1 from "../../assets/Media logos/AT.png";
// import channel11 from "../../assets/Media logos/IS.png";
// import channel12 from "../../assets/Media logos/JN.png";
// import channel13 from "../../assets/Media logos/LIS.png";
// import channel15 from "../../assets/Media logos/NO.png";
// import channel16 from "../../assets/Media logos/PI.png";
// import channel17 from "../../assets/Media logos/PN.png";
// import channel18 from "../../assets/Media logos/RD.png";
// import channel20 from "../../assets/Media logos/riz.png";
// import channel21 from "../../assets/Media logos/SUS.png";
// import channel23 from "../../assets/Media logos/TN.png";
// import channel24 from "../../assets/Media logos/TOI.png";
// import channel25 from "../../assets/Media logos/YT.png";
// import channel26 from "../../assets/Home Page/news-channels/image 2626.png";
import tick from "../../assets/Home Page/green-tick.png";

import Spacer from "../common/Spacer";
import { useNavigate } from "react-router-dom";

const Media = () => {
  const navigate = useNavigate();
  const news = [
    {
      channel: channel2,
      link: "https://x.com/CNBCTV18News/status/1816851062147703188?t=xknZdPkiVdVvnlz9yoH5WA&s=08",
    },
    {
      channel: channel4,
      link: "https://m.dailyhunt.in/news/india/english/republic+news+india-epaper-dhfacc36dfce9c4bb68db0e89d033c921b/shitiz+ruhail+cofounder+of+digital+darwin+on+navigating+digital+darwinism+with+innovation+and+agility-newsid-dhfacc36dfce9c4bb68db0e89d033c921b_7d016980a70e11efadefaeaa8d95810d?sm=Y",
    },
    {
      channel: channel14,
      link: "https://www.youtube.com/watch?v=kBID09bzPSM",
    },
    {
      channel: channel3,
      link: "https://cxotoday.com/interviews/digital-darwin-revolutionizing-sports-tech-with-computer-vision-generative-ai-and-integrated-analytics/#google_vignette",
    },
    {
      channel: channel6,
      link: "https://www.firstpost.com/connect/the-1-tn-conversation-how-ai-assistants-will-revolutionise-our-lives-13832983.html",
    },
  ];
  const news2 = [
    {
      channel: channel19,
      link: "https://republicnewsindia.com/digital-darwin-partners-with-google-to-enhance-ai-innovation-in-sports-and-healthcare/",
    },
    {
      channel: channel22,
      link: "https://times-bulletin.com/shitiz-ruhail-co-founder-of-digital-darwin-on-navigating-digital-darwinism-with-innovation-and-agility/",
    },
    {
      channel: channel7,
      link: "https://hindustansaga.com/shitiz-ruhail-co-founder-of-digital-darwin-on-navigating-digital-darwinism-with-innovation-and-agility/",
    },
    {
      channel: channel5,
      link: "https://flipboard.com/@republicnewsind/-shitiz-ruhail-co-founder-of-digital-dar/a-6Lijr9cvT-C6r6m3L0fJ_A%3Aa%3A3544623556-4784a05b9a%2Frepublicnewsindia.com",
    },
    {
      channel: channel9,
      link: "https://theindianbulletin.com/shitiz-ruhail-co-founder-of-digital-darwin-on-navigating-digital-darwinism-with-innovation-and-agility/",
    },
    {
      channel: channel10,
      link: "https://indiansentinel.in/shitiz-ruhail-co-founder-of-digital-darwin-on-navigating-digital-darwinism-with-innovation-and-agility/",
    },
  ];
  return (
    <>
    <div
      className={`${styles?.["white-banner"]} ${styles?.["flex-column"]} ${styles?.[""]} `}
    >
      <h1>Media Coverage</h1>
      <Spacer height={"2rem"} />

      <div
        className={`  ${styles?.["m-auto"]} ${styles?.["wrap"]}  ${styles?.["w-90"]}  `}
      >
        <Marquee speed={30}>
          {news?.map((data) => (
            <div
              onClick={() => window.open(data.link, "_blank")}
              style={{ margin: "0rem 1rem", width: "200px" }}
              className={`${styles?.["media-grid-item"]} ${styles?.["flex-column"]} ${styles?.["pointer"]} `}
            >
              <img style={{ width: "100%" }} src={data?.channel} alt="" />
            </div>
          ))}
        </Marquee>
        <Spacer height={"2rem"}/>
        <Marquee speed={30} direction="right">
          {news2?.map((data) => (
            <div
              onClick={() => window.open(data.link, "_blank")}
              style={{ margin: "0rem 1rem", width: "200px" }}
              className={`${styles?.["media-grid-item"]} ${styles?.["flex-column"]} ${styles?.[""]} `}
            >
              <img style={{ width: "100%" }} src={data?.channel} alt="" />
            </div>
          ))}
        </Marquee>
      </div>
      <Spacer height={"4rem"} />
      <div
        
        className={`${styles?.["outcome-banner"]} ${styles?.["mobileWithNone"]} ${styles?.["w-90"]} ${styles?.["m-auto"]} ${styles?.["m-t-3"]} `}
      >
        <h1>
          Achieving 
          <span className={`${styles?.["gradiant-blue-col"]}  `}>
            Guaranteed{" "}
          </span>
          Outcomes and ROI
        </h1>
        <div>Join leading healthcare providers and:</div>
        <div>
          <div
            className={`${styles?.["flex-gap"]} ${styles?.[""]} ${styles?.[""]} ${styles?.["m-t-3"]} `}
          >
            <img style={{ width: "27px" }} src={tick} alt="" />
            Enhanced patient care
          </div>
          <div
            className={`${styles?.["flex-gap"]} ${styles?.[""]} ${styles?.[""]} ${styles?.["m-t-3"]} `}
          >
            <img style={{ width: "27px" }} src={tick} alt="" />
            Higher capacity, less headcount
          </div>
          <div
            className={`${styles?.["flex-gap"]} ${styles?.[""]} ${styles?.[""]} ${styles?.["m-t-3"]} `}
          >
            <img style={{ width: "27px" }} src={tick} alt="" />
            Acquire and retain more patients
          </div>
        </div>
        <button
          onClick={() => navigate("/contactus")}
          className={` ${styles?.["m-t-3"]}  ${styles?.["req-button2"]} ${styles?.["primary-color"]} ${styles?.["gradiant-light-blue"]}`}
        >
          Request Demo
        </button>
      </div>
      <Spacer height={"2rem"}/>
    </div>
    </>
  );
};
export default Media;
