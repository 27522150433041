import React from "react";
import styles from "../../styles/main.module.css";
import stats from "../../assets/linewhite-background.png";
import Spacer from "./Spacer";
import { useMediaQuery } from "react-responsive";

const NewsFeed = ({
  isNews = false,
  isLeft = false,
  title = "",
  summary = "",
  img = "",
  informerLink = "",
  date = "",
  link = "",
}) => {
  const isMobile=useMediaQuery({maxWidth:"500px"})
  return (
    <div
      className={`${styles?.["m-auto"]} ${styles?.["flexMobileWrap"]} ${styles?.["w-80"]} ${styles?.["flex-between"]}  ${styles?.["value-cover"]}  `}
      style={{ gap:isMobile?"0rem": "2rem" }}
    >
      {isLeft ? (
        <>
          <div
            style={{ lineHeight: "39px", textAlign: "justify" }}
            className={` ${isMobile?styles['w-100']:styles?.["w-50"]}  ${styles?.["value-cover"]} `}
          >
            {isNews ? (
              <>
                <div>{date}</div>
                <Spacer height={"1rem"} />
                <div style={{ marginTop: "-25px", marginBottom: "1rem" }}>
                  <b>{informerLink}</b>
                </div>
              </>
            ) : (
              <></>
            )}
            {isNews ? (
              <h2>{summary}</h2>
            ) : (
              <>
                {" "}
                <h1>{title}</h1>
                <div style={{ lineHeight: "32px"  }}>
                  {summary}
                </div>
              </>
            )}

            <div
              onClick={() => window.open(link, "_blank")}
              style={{ color: "#FF6B6B", fontWeight: "500" }}
            >
              Read More
            </div>
          </div>

          <div className={`${ isMobile?styles['w-100']:styles?.["w-50"]} `}>
            <img className={`${styles?.["news-img"]}`} src={img} alt="" />
          </div>
        </>
      ) : (
        <>
          <div className={`${isMobile?styles['w-100']:styles?.["w-50"]} `}>
            <img className={`${styles?.["news-img"]}`} src={img} alt="" />
          </div>
          <div
            style={{ lineHeight: "39px", textAlign: "justify" }}
            className={` ${isMobile?styles['w-100']:styles?.["w-50"]}  ${styles?.["value-cover"]} `}
          >
            {isNews ? (
              <>
                <div>{date}</div>
                <Spacer height={"1rem"} />
                <div style={{ marginTop: "-25px", marginBottom: "1rem" }}>
                  <b>{informerLink}</b>
                </div>
              </>
            ) : (
              <></>
            )}
            {isNews ? (
                            <h2>{summary}</h2>

            ) : (
              <>
                {" "}
                <h1>{title}</h1>
                <div style={{ lineHeight: "32px" }}>
                  {summary}
                </div>
              </>
            )}

            <div
              onClick={() => window.open(link, "_blank")}
              style={{ color: "#FF6B6B", fontWeight: "500" }}
            >
              Read More
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default NewsFeed;
