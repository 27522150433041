import React from "react";
import styles from "../../styles/main.module.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
const LanguageCard = ({
  img = "",
  language = "",
  name = "",
  title = "",
  specialization = "",
  type = "",
  action = "",
}) => {
  const dispatch=useDispatch()
  const navigate = useNavigate();
  const isMobile=useMediaQuery({maxWidth:"500px"})
  return (
    <div style={isMobile?{ width:"80%" }:{}} className={`${styles?.["language-card"]} ${styles?.["m-auto"]} `}>
      <div style={{ position: "relative" }}>
        <img src={img} alt="" />
        <span className={`${styles?.["language-tab"]} `}>{language}</span>
      </div>

      <div className={`${styles?.["m-l-3"]} ${styles?.["language-content"]}`}>
        <div className={`${styles?.["head-sm"]}`}>{name}</div>
        <div className={`${styles?.["language-title"]}`}>{title}</div>
        <div className={`${styles?.["flex-gap"]}`}>
          <span className={`${styles?.["language-spcl"]}`}>
            {specialization}
          </span>
          <span className={`${styles?.["language-spcl"]}`}>{type}</span>
        </div>
      </div>
      <div
        className={` ${styles?.["language-content"]} ${styles?.["p-t-1"]} ${styles?.["p-b-1"]}`}
      >
        <div className={`${styles?.["language-action"]}`}>
          <svg
            width="15"
            height="24"
            viewBox="0 0 15 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.8571 19.6364H2.14286V4.36364H12.8571M12.8571 0H2.14286C0.953571 0 0 0.970909 0 2.18182V21.8182C0 22.3968 0.225765 22.9518 0.627628 23.361C1.02949 23.7701 1.57454 24 2.14286 24H12.8571C13.4255 24 13.9705 23.7701 14.3724 23.361C14.7742 22.9518 15 22.3968 15 21.8182V2.18182C15 1.60316 14.7742 1.04821 14.3724 0.63904C13.9705 0.22987 13.4255 0 12.8571 0Z"
              fill="#4B4B4B"
            />
          </svg>

          <span>{action}</span>
        </div>
        <div className={`${styles?.["center"]}`}>
          <button onClick={()=>{navigate("/contactus");dispatch({type:"ADD_BOTNAME",payload:name})}} className={`${styles?.["language-req-button"]}`}>
            Request Demo
          </button>
        </div>
      </div>
    </div>
  );
};
export default LanguageCard;
