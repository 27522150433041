import React from "react";
// import { slide as Menu } from 'react-burger-menu'
import styles from "../../styles/main.module.css";
import logo from "../../assets/healthmates-new-logo.png";
import { redirect, useNavigate } from "react-router-dom";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { useMediaQuery } from "react-responsive";
import "react-modern-drawer/dist/index.css";
import Drawer from "react-modern-drawer";
const Navbar = () => {
  const isMobileOrTab = useMediaQuery({ maxWidth: "900px" });
  console.log(isMobileOrTab);
  const navigate = useNavigate();
  return (
    <div className={`${styles?.["flex-between"]} ${styles?.["navbar"]}`}>
      <div
        style={{ gap: "6rem" }}
        className={`${styles?.["flex-gap"]} ${styles?.["w-50"]}`}
      >
        <div
          onClick={() => navigate("/")}
          style={{ width: isMobileOrTab ? "130px" : "72%", maxWidth: "172px" }}
          className={`  ${styles?.["pointer"]}  `}
        >
          <img style={{ width: "100%" }} src={logo} alt="" />
        </div>
        {!isMobileOrTab ? (
          <div className={`${styles?.["flex-between"]} ${styles?.["w-40"]}`}>
            {/* <div
            onClick={() => navigate("/about")}
            className={` ${styles?.["pointer"]}  ${
              window.location.pathname != "/about"
                ? styles?.["tab"]
                : styles?.["active-tab"]
            } `}
          >
            About us
          </div> */}
            <div
              onClick={() => navigate("/contactus")}
              className={` ${styles?.["pointer"]}  ${
                window.location.pathname != "/contactus"
                  ? styles?.["tab"]
                  : styles?.["active-tab"]
              } `}
            >
              {" "}
              Contact us
            </div>
            <div
              onClick={() => navigate("/research")}
              className={` ${styles?.["pointer"]}  ${
                window.location.pathname != "/research"
                  ? styles?.["tab"]
                  : styles?.["active-tab"]
              } `}
            >
              {" "}
              Research
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <button
        onClick={() => navigate("/contactus")}
        className={`${styles?.["gradiant-button"]} ${styles?.["primary-color"]} ${styles?.["req-button"]}`}
      >
        Request Demo
      </button>

      {isMobileOrTab ? <Hamburger /> : <></>}
    </div>
  );
};
export default Navbar;

const Hamburger = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <>
      <div style={{ marginLeft: "1rem" }} onClick={toggleDrawer}>
        <svg
          width="25"
          height="25"
          viewBox="0 0 20 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 16V13.3333H20V16H0ZM0 9.33333V6.66667H20V9.33333H0ZM0 2.66667V0H20V2.66667H0Z"
            fill="white"
          />
        </svg>
      </div>
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="right"
        className={styles.menus}
      >
        <div className={`${styles?.["mainMenu"]} ${styles?.["head-md"]} `}>
          Main Menu
        </div>
        <hr />

        <div
          onClick={() => navigate("/")}
          className={` ${styles?.["pointer"]}  ${
            window.location.pathname != "/"
              ? styles?.["menu"]
              : styles?.["menuActive"]
          } `}
        >
          Home
        </div>

        <div
          onClick={() => navigate("/contactus")}
          className={` ${styles?.["pointer"]}  ${
            window.location.pathname != "/contactus"
              ? styles?.["menu"]
              : styles?.["menuActive"]
          } `}
        >
          Contact Us
        </div>

        <div
          onClick={() => navigate("/research")}
          className={` ${styles?.["pointer"]}  ${
            window.location.pathname != "/research"
              ? styles?.["menu"]
              : styles?.["menuActive"]
          } `}
        >
          Research
        </div>
      </Drawer>
    </>
  );
};
