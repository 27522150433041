import React from "react";
import styles from "../../styles/main.module.css";
import whiteLine from "../../assets/Home Page/video-play-banner.png";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const JoinTeam = () => {
    const navigate=useNavigate()
    const isMobile=useMediaQuery({maxWidth:"500px"})
  return (
    <div className={`${styles?.["video-banner"]} ${styles?.["w-80"]} ${styles?.["m-auto"]}  `}>
      <img
        style={isMobile?{  }:{height: "350px"}}
        className={`${styles?.["cover-image"]} `}
        src={whiteLine}
        alt=""
      />
      <div
        className={`${styles?.["cover-content"]} ${styles?.["flex-column"]} `}
      >
        <h1
          className={`${styles?.["w-80"]} ${styles?.["font-w-4"]} ${styles?.["m-auto"]} ${styles?.["center"]}`}
        >
          Join our Research Team
        </h1>
        <div onClick={()=>navigate("/career")} className={`${styles?.["pointer"]}`} >
        <button className={`${styles?.["join-button"]}`}>Join Now</button>


        </div>
        
      </div>
    </div>
  );
};
export default JoinTeam;
