// import React, { useState, useEffect } from "react";
 
// const TypeWriter = ({
//   sentences,
//   typingSpeed = 100,
//   delayBetweenSentences = 1500,
// }) => {
//   const [currentSentenceIndex, setCurrentSentenceIndex] = useState(0);
//   const [displayedText, setDisplayedText] = useState("");
//   const [isTyping, setIsTyping] = useState(true);
 
//   useEffect(() => {
//     let index = 0;
//     let typingInterval;
 
//     const typeSentence = () => {
//       typingInterval = setInterval(() => {
//         const sentence = sentences[currentSentenceIndex];
//         if (index < sentence.length) {
//           setDisplayedText((prev) => prev + sentence.charAt(index));
//           index++;
//         } else {
//           clearInterval(typingInterval);
 
//           if (sentences.length > 1) {
//             // If multiple sentences, move to the next after a delay
//             setTimeout(() => {
//               setIsTyping(false);
//             }, delayBetweenSentences);
//           }
//         }
//       }, typingSpeed);
//     };
 
//     if (isTyping) {
//       setDisplayedText(""); // Clear text before typing
//       typeSentence();
//     } else if (sentences.length > 1) {
//       // Only move to the next sentence if there are multiple sentences
//       setCurrentSentenceIndex((prev) =>
//         prev === sentences.length - 1 ? 0 : prev + 1
//       );
//       setIsTyping(true);
//     }
 
//     return () => clearInterval(typingInterval);
//   }, [
//     currentSentenceIndex,
//     isTyping,
//     sentences,
//     typingSpeed,
//     delayBetweenSentences,
//   ]);
 
//   return <div>{displayedText}</div>;
// };
 
// export default TypeWriter;
import React, { useState, useEffect, useRef } from "react";
 
const Typewriter = ({
  sentences = [""], // Default to an empty string if no sentences are passed
  typingSpeed = 100,
  delayBetweenSentences = 1500,
  loop = false, // Option to loop sentences
}) => {
  const [displayedText, setDisplayedText] = useState("");
  const [isTyping, setIsTyping] = useState(true);
  const currentSentenceIndexRef = useRef(0); // Use ref for the current sentence index
  const typingIndexRef = useRef(0); // Track the typing position
  const timeoutRef = useRef(null); // Store the timeout ID
  const intervalRef = useRef(null); // Store the typing interval ID
 
  useEffect(() => {
    const typeSentence = () => {
      const sentence = sentences[currentSentenceIndexRef.current];
      if (typingIndexRef.current < sentence.length) {
        // Add one character at a time
        setDisplayedText((prev) => prev + sentence.charAt(typingIndexRef.current));
        typingIndexRef.current += 1;
      } else {
        // Sentence completed
        clearInterval(intervalRef.current);
        if (loop || sentences.length > 1) {
          timeoutRef.current = setTimeout(() => {
            setIsTyping(false); // Signal to move to the next sentence
          }, delayBetweenSentences);
        }
      }
    };
 
    if (isTyping) {
      setDisplayedText(""); // Reset text before typing
      typingIndexRef.current = 0; // Reset the typing index
      intervalRef.current = setInterval(typeSentence, typingSpeed); // Start typing
    } else if (loop || sentences.length > 1) {
      // Move to the next sentence
      currentSentenceIndexRef.current =
        (currentSentenceIndexRef.current + 1) % sentences.length;
      setIsTyping(true); // Start typing the next sentence
    }
 
    return () => {
      // Cleanup intervals and timeouts to avoid memory leaks
      clearInterval(intervalRef.current);
      clearTimeout(timeoutRef.current);
    };
  }, [isTyping, loop, sentences, typingSpeed, delayBetweenSentences]);
 
  return <div style={{ textAlign:"center",width:"90%",margin:"auto  " }}>{displayedText}</div>;
};
 
export default Typewriter;