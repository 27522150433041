import React, { useState } from "react";

import styles from "../styles/main.module.css";
import Spacer from "../components/common/Spacer";
import MainCover from "../components/Career/MainCover";
import { add_join_team } from "../api/Form/FormApi";
import Success from "../components/common/Success";
import Error from "../components/common/Error";
import { useMediaQuery } from "react-responsive";

const Career = () => {
  const isMobile=useMediaQuery({maxWidth:"700px"})
  const [file, setFile] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError,setIsError]=useState(false)
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [contactInfo, setContactInfo] = useState({
    first_name: "",
    last_name: "",
    email: "",
    contactNumber: "",
    note: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContactInfo((prev) => ({ ...prev, [name]: value }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("first_name", contactInfo.first_name);
    formdata.append("last_name", contactInfo.last_name);
    formdata.append("email", contactInfo.email);
    formdata.append("mobile_number", contactInfo.contactNumber);
    formdata.append("note", contactInfo.note);
    formdata.append("cv", file);
    console.log(contactInfo);
    const res = await add_join_team(formdata);
    console.log(res);
    if (res.message == "Join Team form submitted successfully!") {
      setIsSuccess(true);
      setContactInfo({
        first_name: "",
        last_name: "",
        email: "",
        contactNumber: "",
        note: "",
      });
    }else{
      setIsError(true)
    }
  };
  const handleFileChange = (e) => {
    console.log(e.target.files[0]);
    const cv = e.target.files[0];
    setFile(cv);
    setUploadedFileName(cv.name);
  };
  return (
    <div
      data-aos="fade-down"
      
    >
      
      {/* <Spacer height={"2rem"} /> */}
      {isSuccess ? (
        <>
          <Success text="Thank you for applying! We've received your application and appreciate your interest in joining our team. We'll review your submission and reach out if you're shortlisted. Best of luck!" />
        </>
      ) :  isError ? (
        <Error />
      ) : (
        <>
        <MainCover title="Join the Team" />
        <div
          style={{ gap: "2rem", border: "none" }}
          className={`${isMobile?styles['w-80']:styles?.["w-60"]} ${styles?.["form-container"]} ${styles?.["flex-column-str"]} ${styles?.["m-auto"]}  `}
        >
          <form style={{ gap:"1rem" }} className={`${styles?.["flex-column-str"]}`} onSubmit={handleSubmit}>
            <div
              style={isMobile?{display:"block",width:"100%"}:{ gap: "1.5rem" }}
              className={`${styles?.["flex-gap"]} ${styles?.[""]}  `}
            >
              <div className={`${isMobile?styles['w-100']:styles?.["w-50"]} ${styles?.[""]}  `}>
                <label>Name</label>
                <input
                  required
                  name="first_name"
                  value={contactInfo.first_name}
                  onChange={handleInputChange}
                  placeholder="First Name"
                  className={`${styles?.["inpt-box"]} ${styles?.[""]}  `}
                  type="text"
                />
              </div>
              <div className={`${isMobile?styles['w-100']:styles?.["w-50"]} ${styles?.[""]}  `}>
                <div style={{ minHeight: "1.2rem" }}></div>

                <input
                  name="last_name"
                  value={contactInfo.last_name}
                  onChange={handleInputChange}
                  placeholder="Last Name"
                  className={`${styles?.["inpt-box"]} ${styles?.[""]}  `}
                  type="text"
                />
              </div>
            </div>
            <div>
              <label>Email</label>
              <input
                required
                name="email"
                onChange={handleInputChange}
                value={contactInfo.email}
                placeholder="Type..."

                // placeholder="First Name"
                className={`${styles?.["inpt-box"]} ${styles?.[""]}  `}
                type="email"
              />
            </div>
            <div>
              <label>Contact Number</label>
              <input
                required
                name="contactNumber"
                onChange={handleInputChange}
                value={contactInfo.contactNumber}
                placeholder="Type..."

                // placeholder="First Name"
                className={`${styles?.["inpt-box"]} ${styles?.[""]}  `}
                type="number"
              />
            </div>
            <div>
            Cover Letter{" "}
            <span style={{ fontSize: ".8rem" }}>(Max 1000 characters)</span>{" "}
              <textarea
                required
                name="note"
                onChange={handleInputChange}
                rows="3"
                style={{
                  resize: "vertical",
                  overflow: "auto",
                  height: "6rem",
                  paddingTop: ".5rem",
                }}
                maxlength="1000"
                placeholder="Type..."
                value={contactInfo.note}
                // placeholder="First Name"
                className={`${styles?.["inpt-box"]} ${styles?.[""]}  `}
                type="text"
              />
            </div>
            <Spacer height={"1rem"} />
            <div className={`${styles?.["flex-between"]}`}>
              <input
                required
                // accept=".pdf,.doc,.docx"
                onChange={handleFileChange}
                id="file-inpt"
                style={{ display: "none" }}
                type="file"
              />
              <button  className={`${styles?.["outline-button"]}`}>
                <svg
                  width="15"
                  height="19"
                  viewBox="0 0 22 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.71344 10.3228L6.24395 22.209C6.077 23.081 6.25703 23.9831 6.7452 24.7207C7.23337 25.4582 7.99053 25.9721 8.85329 26.1513C9.71605 26.3306 10.6152 26.1609 11.3568 25.6788C12.0983 25.1967 12.6228 24.441 12.817 23.5747L16.0645 7.99712C16.2278 7.25608 16.2435 6.49022 16.1107 5.74393C15.9778 4.99763 15.6991 4.28576 15.2907 3.64957C14.8823 3.01338 14.3523 2.46553 13.7314 2.03777C13.1105 1.61002 12.4111 1.31087 11.6737 1.15767C10.9363 1.00447 10.1756 1.00027 9.43575 1.14531C8.69586 1.29035 7.99148 1.58174 7.36344 2.00259C6.7354 2.42345 6.1962 2.96539 5.77709 3.59701C5.35798 4.22863 5.0673 4.93736 4.9219 5.68211L1.66323 21.3668C1.43092 22.4096 1.40664 23.4878 1.59183 24.5388C1.77701 25.5899 2.16796 26.5927 2.74194 27.4891C3.31591 28.3855 4.06147 29.1575 4.93526 29.7602C5.80905 30.3629 6.79364 30.7844 7.83179 31.0001C8.86994 31.2158 9.94093 31.2214 10.9825 31.0166C12.0241 30.8118 13.0154 30.4006 13.899 29.8071C14.7825 29.2135 15.5405 28.4494 16.1291 27.5591C16.7176 26.6688 17.1248 25.6701 17.3271 24.6211L20.3722 9.96435"
                    stroke="white"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                  />
                </svg>
                <label htmlFor="file-inpt">Attach CV</label>
              </button>
              <button
                // onClick={handleSubmit}
                type="submit"
                className={`${styles?.["gradiant-button"]} ${styles?.["submit-button"]}`}
              >
                Submit
              </button>
            </div>
            <Spacer height={"1rem"} />
            {uploadedFileName !== "" ? (
              <div
                className={`${styles?.["outline-button"]} ${styles?.["flex-between"]}`}
              >
                {uploadedFileName}
                <div
                  className={`${styles?.["pointer"]} `}
                  onClick={() => {
                    setUploadedFileName("");
                    setFile(null);
                  }}
                >
                  <svg
                    width="44px"
                    height="44px"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    stroke="#ffffff"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        d="M16 8L8 16M8.00001 8L16 16"
                        stroke="#ffffff"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>{" "}
                    </g>
                  </svg>
                </div>
              </div>
            ) : (
              <></>
            )}
          </form>
        </div></>
      )}

      <Spacer height={"1rem"} />
    </div>
  );
};
export default Career;
