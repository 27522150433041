import React from "react";
import styles from "../../styles/main.module.css";
import background from "../../assets/background.png";
import { useMediaQuery } from "react-responsive";

const MainCover = ({ title = "", subTitle = "" }) => {
  const isMobile = useMediaQuery({ maxWidth: "500px" });
  const isDesktop = useMediaQuery({ minWidth: "501px", maxWidth: "900px" });

  return (
    <>
      <div>
        <div
          style={{
            minHeight: isMobile ? "120px" : isDesktop ? "250px" : "300px",
            backgroundImage: `url(${background})`,
            justifyContent: isMobile || isDesktop ? "center" : "end",
            alignItems: isMobile || isDesktop ? "center" : "start",
          }}
          className={`${styles?.["about-cover"]} ${styles?.["p-l-3"]} ${styles?.["flex-column-end"]} `}
        >
          <h1
            className={`${isMobile ? styles["w-80"] : styles?.["w-50"]}   ${
              styles?.["m-l-3"]
            } ${isMobile || isDesktop ? styles["head-lg"] : styles[""]} ${
              isMobile || isDesktop ? styles["center"] : styles["left"]
            } `}
          >
            {title}
          </h1>
        </div>
        {/* <div  className={`${styles?.["gradiant-button"]} ${styles?.["p-t-3"]} ${styles?.["p-b-3"]} `}>
      <h3 className={`${styles?.["w-50"]} ${styles?.["m-auto"]} ${styles?.["center"]}  `} >
    {subTitle}</h3>
</div> */}
        <div></div>
      </div>
    </>
  );
};
export default MainCover;
