import React, { useState, useRef } from "react";
import chat from "../../assets/Home Page/Mask group.png";

import styles from "../../styles/voice.module.css";
import { useEffect } from "react";
import TypeWriter from "./TypeWriter";
import { chat_bot, create_user, delete_bot } from "../../api/ChatBot/ChatApi";

const VoiceToText = () => {
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const [isOutcome, setIsOutcome] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [chatRes, setChatRes] = useState("");
  const [chats, setChats] = useState("");

  const [audioFile, setAudioFile] = useState(null);
  const [text, setText] = useState("");
  const [flag, setFlag] = useState(0);
  const [attempt, setAttempt] = useState(0);
  const [isListening, setIsListening] = useState(false);
  const [isReady, setIsReady] = useState(true); // To handle restarts
  const recognition = useRef(null);
  const idleTimeout = useRef(null);

  if (!("webkitSpeechRecognition" in window)) {
    alert("Speech Recognition is not supported in this browser.");
  } else {
    recognition.current = new window.webkitSpeechRecognition();
    recognition.current.lang = "en-US";
    recognition.current.interimResults = false;
    recognition.current.maxAlternatives = 1;

    // Handle speech recognition result
    recognition.current.onresult = (event) => {
      clearTimeout(idleTimeout.current); // Clear idle timeout since speech is detected
      const speechToText = event.results[0][0].transcript;
      setText(speechToText);

      // Automatically stop after user finishes speaking
      idleTimeout.current = setTimeout(() => {
        stopListening();
      }, 3000); // Adjust delay for user pause detection
    };

    // Handle recognition errors
    recognition.current.onerror = (event) => {
      console.error("Speech recognition error:", event.error);
      stopListening();
    };

    // Reset when recognition ends
    recognition.current.onend = () => {
      setIsListening(false); // Reset listening state
      setIsReady(true); // Allow restarting
    };
  }

  const startListening = async () => {
    // setIsListening(!isListening)
    console.log(isListening);
    if (!isListening && isReady) {
      // Start listening only if not already listening
      try {
        // Request microphone access
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });

        // Initialize MediaRecorder
        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorderRef.current = mediaRecorder;
        audioChunksRef.current = []; // Reset audio chunks

        // Collect audio data
        mediaRecorder.ondataavailable = (event) => {
          audioChunksRef.current.push(event.data);
        };

        // Handle stop
        mediaRecorder.onstop = () => {
          const audioBlob = new Blob(audioChunksRef.current, {
            type: "audio/wav",
          });
          setAudioFile(audioBlob);
        };

        // Start recording
        mediaRecorder.start();
        setIsListening(true);
        setIsReady(false); // Prevent restarting until stop

        // Idle timeout: stop if no sound after 4 seconds
        idleTimeout.current = setTimeout(() => {
          stopListening();
        }, 4000);
      } catch (err) {
        console.error("Error accessing microphone:", err);
      }
    } else {
      stopListening(); // Stop listening if already listening
    }
  };

  const stopListening = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
    setIsListening(false);
    setIsReady(true); // Allow restarting
    clearTimeout(idleTimeout.current); // Clear any pending idle timeout
  };

  useEffect(() => {
    const create = async () => {
      const userNumber = localStorage?.getItem("user_number");
      if (userNumber == null) {
        const res = await create_user();
        console.log(res);
        localStorage.setItem("user_number", res?.data?.number);
      }
      console.log(userNumber);
    };

    create();
  }, []);
  function isEmptyObject(obj) {
    return Object.keys(obj).length === 0;
  }
  const call_retry = async (maxAttempts = 100) => {
    if (attempt < maxAttempts && attempt !== 0) {
      const userNumber = localStorage?.getItem("user_number");
      const del = await delete_bot({
        mobile_number: userNumber,
        Bot_ID_INPUT: "100",
        Session_ID: `20241101061947-100-${userNumber}`,
      });
      console.log(del, del?.data);
      if (!isEmptyObject(del?.data)) {
        console.log(del?.data);
        setChatRes(del?.data?.bot_audio);
        setIsOutcome(true);
        setChats(del?.data?.Body);

        setAttempt(maxAttempts);
      } else {
        setTimeout(() => {
          setAttempt((prev) => prev + 1);
        }, 2000);
      }
    }
  };
  useEffect(() => {
    if (attempt > 0) {
      call_retry();
    }
  }, [attempt]);
  useEffect(() => {
    const userNumber = localStorage?.getItem("user_number");

    const chat = async () => {
      if (userNumber !== "" && audioFile != null) {
        const formData = new FormData();

        formData.append("files", audioFile, "recording.wav");
        formData.append("Bot_ID_INPUT", 100);
        formData.append("Bot_ID_OUTPUT", 100);
        formData.append("mobile_number", userNumber);
        formData.append("Session_ID", `20241101061947-100-${userNumber}`);
        formData.append("flag", flag);
        const res = await chat_bot(formData);
        console.log(res);
        if (res?.output?.status == "success") {
          setAttempt(1);
          setIsOutcome(false);
          setFlag(1);
        }
      }
    };
    chat();
  }, [audioFile]);

  return (
    <>
      <div className={styles.voiceContainer}>
        <button
          className={`${styles.micButton} ${
            isListening ? styles.listening : ""
          }`}
          style={{ zIndex: "1" }}
          onClick={startListening}
          disabled={(!isReady && !isListening) || isOutcome==false || isPlaying} // Ensure button is usable only when ready or listening
          // disabled={true}
        >
          <img src={chat} alt="" />
          <div className={styles.hover}>
            <div className={styles.icon}>
              <svg
                width="32"
                height="44"
                viewBox="0 0 32 44"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 27.7895C14.0952 27.7895 12.4762 27.114 11.1429 25.7632C9.80952 24.4123 9.14286 22.7719 9.14286 20.8421V6.94737C9.14286 5.01754 9.80952 3.37719 11.1429 2.02632C12.4762 0.675439 14.0952 0 16 0C17.9048 0 19.5238 0.675439 20.8571 2.02632C22.1905 3.37719 22.8571 5.01754 22.8571 6.94737V20.8421C22.8571 22.7719 22.1905 24.4123 20.8571 25.7632C19.5238 27.114 17.9048 27.7895 16 27.7895ZM13.7143 44V36.8789C9.75238 36.3386 6.47619 34.5439 3.88571 31.4947C1.29524 28.4456 0 24.8947 0 20.8421H4.57143C4.57143 24.0456 5.6861 26.7767 7.91543 29.0354C10.1448 31.294 12.8396 32.4226 16 32.4211C19.1604 32.4195 21.856 31.2902 24.0869 29.0331C26.3177 26.7759 27.4316 24.0456 27.4286 20.8421H32C32 24.8947 30.7048 28.4456 28.1143 31.4947C25.5238 34.5439 22.2476 36.3386 18.2857 36.8789V44H13.7143Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
        </button>
        {isListening && (
          <div className={styles.waveContainer}>
            <div className={styles.wave}></div>
            <div className={styles.wave}></div>
            <div className={styles.wave}></div>
          </div>
        )}
      </div>
      {isPlaying ? (
        <div className={styles.pulseWaveContainer}>
          <div className={styles.pulseBar}></div>
          <div className={styles.pulseBar}></div>
          <div className={styles.pulseBar}></div>
          <div className={styles.pulseBar}></div>
          <div className={styles.pulseBar}></div>
          <div className={styles.pulseBar}></div>
          <div className={styles.pulseBar}></div>
          <div className={styles.pulseBar}></div>
        </div>
      ) : (
        <></>
      )}
      {chats !== "" && !isPlaying ? (
        <div className={`${styles.outlineContainer}`}>{chats}</div>
      ) : (
        <></>
      )}
      {isOutcome == false ? <div className={styles.loader}></div> : <></>}
      <p className={styles.recognizedText}>
        {text ? (
          `You : ${text}`
        ) : (
          <span>
            {isListening ? (
              "Ask something"
            ) : (
              <span>
                Click to experience a conversation <br /> with an AI Healthmate
                right now
              </span>
            )}
          </span>
        )}
      </p>
      {chatRes !== "" ? (
        <>
          <audio
            onPlay={() => setIsPlaying(true)}
            onPause={() => setIsPlaying(false)}
            src={chatRes}
            autoPlay
          ></audio>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default VoiceToText;
