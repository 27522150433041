import React from "react";   
import styles from "../../styles/main.module.css"
import protection from "../../assets/Home Page/gaurd.png"
import { useMediaQuery } from "react-responsive";
const SecuritySection = () => {
    const isMobile=useMediaQuery({maxWidth:"700px"})
    return (
        <div className={`${styles?.["blue-banner"]} ${styles?.[""]}  ${styles?.["flex-around"]} ${styles?.["wrap"]}  `}>
           <div  className={`${styles?.["secure-grid"]} ${styles?.["w-100"]}  ${styles?.["fl"]}  `}>
            <div className={`${styles?.["secure-img"]} `}>
                <img style={{ width:"100%" }} src={protection} alt="" />
                <span className={`${styles?.["secure-title"]} `}>PDP</span>
            </div>
            <div className={`${styles?.["secure-img"]} `}><img  style={{ width:"100%" }} src={protection} alt="" />
            <span className={`${styles?.["secure-title"]} `}>GDPR</span>
            </div>
            <div className={`${styles?.["secure-img"]} `}><img style={{ width:"100%" }} src={protection} alt="" />
            <span className={`${styles?.["secure-title"]} `}>HIPAA</span>
            </div>
            <div className={`${styles?.["secure-img"]} `}><img style={{ width:"100%" }} src={protection} alt="" /><span className={`${styles?.["secure-title"]} `}>SOC 2</span></div>

           </div >
           <div style={{ textAlign:"left" }} className={`${styles?.["p-r-3"]} ${!isMobile?styles?.["w-50"]:styles['w-80']}  ${styles?.["fl"]}  `} >
            <h1>
            Millions of Automations, 
            <br />
            <span className={`${styles?.["gradiant-blue-col"]} `} >

            Maximum Security

            </span>
                
            </h1>
            <div>
            Healthmates.AI is dedicated to compliance and security, offering full transparency and access to certifications, documentation, and high-level details on our strict control adherence.
            </div>

           </div>
          
             
        </div>
    );
};
export default SecuritySection;