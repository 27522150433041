import React from "react";
import styles from "../../styles/main.module.css";
import whiteLine from "../../assets/Home Page/info-banner-cover.png";
import { useMediaQuery } from "react-responsive";

const InfoBanner = () => {
  const isMobile=useMediaQuery({maxWidth:"500px"})
  return (
    <div className={`${styles?.["info-banner"]}`}>
      <img
                   style={{ height:isMobile?"200px": "350px" }}

        className={`${styles?.["cover-image"]} `}
        src={whiteLine}
        alt=""
      />
      <div
        className={`${styles?.["cover-content"]} ${styles?.["flex-column"]} `}
      >
        <h2
          className={`${styles?.["w-80"]} ${styles?.["font-w-4"]} ${styles?.["m-auto"]} ${styles?.["center"]}`}
        >
          <b>We don’t</b> believe Generative AI can replace the clinicians!
        </h2>
        <h2
          className={`${styles?.["w-80"]} ${styles?.["font-w-4"]} ${styles?.["center"]}  ${styles?.["m-auto"]}`}
        >
          <b>We do </b> believe it can significantly reduce their burden, acting
          as force multipliers!
        </h2>
      </div>
    </div>
  );
};
export default InfoBanner;
